import React from "react";
import { PiKeyboard, PiVideo    } from "react-icons/pi";
import { RiAiGenerate } from "react-icons/ri";
import YouTube from "react-youtube";
import { YOUTUBE } from "../../constants";

const SlideThree = () => {
    const opts = {
        height: '505',
        width: '853',
    };

    return (
        <div className="dark:bg-black">
            <div className="flex items-center justify-center text-center">
                <div className="text-4xl font-black dark:text-white max-md:text-2xl">How it works</div>
            </div>

            <YouTube key={YOUTUBE} className='justify-center self-center content-center flex mt-12 max-lg:m-3' videoId={YOUTUBE} opts={opts} />

            <div className="lg:flex py-14 max-md:py-14">
              
                <div className="flex flex-col items-center justify-center lg:w-1/3 pt-6">
                    <PiKeyboard className="text-4xl max-md:text-3xl dark:text-white" />
                    <div className="text-2xl max-md:text-lg pt-4 font-bold  dark:text-white">Enter Course Title</div>
                    <div className="text-lg max-md:text-xs text-center px-3 pt-2 font-medium  dark:text-white"  >Enter the course title for which you want to generate content using AI</div>
                </div>

         
                <div className="flex flex-col items-center justify-center lg:w-1/3 pt-6">
                    <RiAiGenerate  className="text-4xl max-md:text-3xl dark:text-white" />
                    <div className="text-2xl max-md:text-lg pt-4 font-bold  dark:text-white">AI Generates Entire Course</div>
                    <div className="text-lg max-md:text-xs text-center px-3 pt-2 font-medium  dark:text-white" >AI will generate topics and subtopics, Images & Videos based on the title you provide</div>
                </div>

               
                <div className="flex flex-col items-center justify-center lg:w-1/3 pt-6">
                    <PiVideo className="text-4xl max-md:text-3xl dark:text-white" />
                    <a href="https://www.youtube.com/channel/UCIsqjF0BhzfOnhkKwi-v54g" className="text-2xl max-md:text-lg pt-4 font-bold dark:text-white hover:underline">
                        Demo & Tutorials
                    </a>
                    <div className="text-lg max-md:text-xs text-center px-3 pt-2 font-medium dark:text-white">
                        See the Ai to Courses genertor in action.
                    </div>
                </div>
            </div>
        </div>
        
    );
};

export default SlideThree;
