//BRAND
export const name = 'AiToCourses';
export const company = 'Jaiz Online Projects';
export const websiteURL = 'https://aitocourses.com';
export const serverURL = 'https://aicourses.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/ai-to-courses.appspot.com/o/logo512x512.png?alt=media&token=460811aa-7237-4700-a8e0-20b3b6d43a65';
export const razorpayEnabled = false;
export const paypalEnabled = true;
export const stripeEnabled = true;
export const paystackEnabled = true;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 12;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 79;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The AiToCourses.com generator revolutionized my content creation process, providing accurate and relevant topics effortlessly. It's a time-saving powerhouse that enhances the quality and relevance of my courses. This is a must-have tool for educators seeking efficiency and impactful online learning experiences.";
export const from = "Dr Midi Saba";
export const profession = 'Dentist';
export const photoURL = 'https://play-lh.googleusercontent.com/sV_ffBmBJt_je4RZHnfaCfcnL-Hy6C14Iol7H5EMj9fzI2GDOonuojdn5t9p6n9IAX8j';

//PAYPAL
export const paypalPlanIdOne = "P-3UG31220Y29205516MZ5VHEY";
export const paypalPlanIdTwo = "P-06854136S55050024MZ5VIIA";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";

//STRIPE
export const stripePlanIdOne = "price_1PwSNtLsY1V3D0TTLSFkrTym";
export const stripePlanIdTwo = "price_1PwSR4LsY1V3D0TTvvWg2e0k";

//PAYSTACK
export const paystackPlanIdOne = "PLN_0nrrsgp71skjat0";
export const paystackPlanIdTwo = "PLN_co1rqztfeh5dmd7";
export const amountInZarOne = '170';
export const amountInZarTwo = '1871';

//SOCIAL SIGNIN
export const googleClientId = "1043983079231-0jejn2ln95ervdc01gtkv0u34s0rottg.apps.googleusercontent.com";

//YOUTUBE
export const YOUTUBE = "fA_KAeLAk7g";

//UNIVERSAL PRICE
export const EURO = 0.92;
export const POUND = 0.78;
export const NAIRA = 1590;
